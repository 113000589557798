body {
  background-color: #001a33;
}

  .App {
   display: flex;
   flex-direction: row;
   font-family: "Barlow", sans-serif; ;
   font-style: normal;
}

.slick-slide {
  padding: 0 10px;
}

.slick-slider{
  padding: 0 10px;
}

.spinning_indicator{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #ffffff;
  opacity: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation{
  background-color: #203660 !important;
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded{
  background-color: #00194D !important;
  border-radius: 50%;

}

.css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded > svg {
  fill: #2192FF !important;
}

.MuiSvgIcon-root{
  fill: #669ACC !important;
  font-size: 2rem !important;
}

.css-eqpfi5-MuiAccordionSummary-content.Mui-expanded{
  margin: 0px !important;
}

.no-border{
  border: none !important;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}
.slick-track {
  margin-left:0 !important; 
}

.ant-tree-list-holder-inner{
  background-color:#0F264F !important;   
  color: white !important;
}

.ant-tree-treenode-selected{
  background-color: #0F264F !important;
}

.ant-tree-node-selected{
  background-color: #0F264F !important;
}

.ant-tree-selected{
  background-color: #0F264F !important;
}

.slick-prev{
  left: -15px !important;
}

.slick-next{
  right: -15px !important;
}
/* the parent */
.slick-list {
  padding: 0 10px;
}

.slick-arrow.slick-next,
.slick-arrow.slick-prev{
background-color: #000000;
height:25px;
width: 25px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
opacity: 0.8;
height:30px;
width:30px;
}

.slick-arrow.slick-next:hover,
.slick-arrow.slick-prev:hover
{
    background-color: black;
    opacity:1;
}


.slick-prev::before,.slick-next::before{
   display:none;
    
    }

.arrows{
height:20px;
width:20px;
margin: 1px;

}

.workbooks{
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
}

.spinning_indicator{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #ffffff;
  opacity: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinning_indicator_views{
  position: absolute;
  width: 100%;
  height: 50%;
  z-index: 10;
  background-color: #ffffff;
  opacity: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard_div{
  display: flex !important;
  flex-direction: column;
}

.MuiAccordion-root{
  box-shadow: none !important;
  border: 2px solid #D0D5DD;
}

.password-icon {
  fill: #7A7C7F !important;
}

.MuiTimeline-root{
  display: flex ;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-left: 6%;
}

ul{
  list-style-type: disc ;
}

.MuiTimelineItem-root::before{
  content: none !important;
}

.MuiTimelineDot-root{
  background-color: white !important;
}

.MuiTimelineConnector-root{
  background-color: white !important;
}